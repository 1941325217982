import { ROUTE_NAME_BROADCAST, APP_ROUTE, ROUTE_NAME_VIDECAM } from '@/const'
const routes = [
  {
    path: '/',
    name: 'route-home',
    component: () => import('./views/Home')
  },
  {
    path: `/${APP_ROUTE.WATCH}/:id`,
    name: 'route-watch',
    component: () => import('./views/Watch')
  },
  {
    path: `/${APP_ROUTE.LIVE}/:id`,
    name: 'route-live',
    component: () => import('./views/Live')
  },
  {
    path: `/${APP_ROUTE.BROADCAST}/new`,
    name: ROUTE_NAME_BROADCAST.NEW,
    component: () => import('./views/Broadcast')
  },
  {
    path: `/${APP_ROUTE.STREAM_SETTING}/:id`,
    name: 'route-stream-setting',
    component: () => import('./views/stream-setting')
  },
  {
    path: `/${APP_ROUTE.STREAM}/:id`,
    name: 'route-stream',
    component: () => import('./views/stream')
  },
  {
    path: `/${APP_ROUTE.BROADCAST}/:id`,
    name: ROUTE_NAME_BROADCAST.EDIT,
    component: () => import('./views/Broadcast')
  },
  {
    path: `/${APP_ROUTE.REPORT}/:id`,
    name: 'route-report',
    component: () => import('./views/Report')
  },
  {
    path: `/${APP_ROUTE.HELP}`,
    name: 'route-help',
    component: () => import('./views/Help')
  },
  // Delete zoom page
  {
    path: `/${APP_ROUTE.AI}`,
    name: 'route-ai',
    component: () => import('./views/ai/ai.vue')
  },
  {
    path: `/${APP_ROUTE.RECORD}`,
    name: 'route-record-page',
    component: () => import('./views/record')
  },
  {
    path: `/${APP_ROUTE.VIDECAM}/new`,
    name: ROUTE_NAME_VIDECAM.NEW,
    component: () => import('./views/videcam/new')
  },
  {
    path: `/${APP_ROUTE.VIDECAM}/admin`,
    name: ROUTE_NAME_VIDECAM.ADMIN,
    component: () => import('./views/videcam/adm')
  },
  {
    path: `/${APP_ROUTE.VIDECAM}/:id`,
    name: ROUTE_NAME_VIDECAM.EDIT,
    component: () => import('./views/videcam/edit')
  },
  {
    path: `/${APP_ROUTE.CONTROL}`,
    name: 'route-control',
    component: () => import('./views/control')
  }
]
export default routes
