<template>
  <div id="app">
    <mts-app-header
      :logo="header.logo"
      :nav="nav"
      :avatar="header.avatar"
      @login="login"
      @logout="logout"
    />

    <!-- fixed svg with id # -->
    <FixedSVG />

    <!-- route -->
    <router-view />

    <!-- панель управление трансляции -->
    <StreamCtrl v-if="isVisibleStreamCtrl" />
    <!-- панель подтверждения удаление карточки трансляции -->
    <WatchCardDel v-if="isVisibleWatchCardDel" />
    <!-- панель подтверждения очистки чата -->
    <StreamChatClear v-if="isVisibleStreamChatClear" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { logoMap, ROUTE_NAME_BROADCAST, APP_ROUTE, URL, ROUTE_NAME_VIDECAM } from '@/const'
import {
  GETTER_STREAM_CTRL,
  GETTER_APP,
  GETTER_WATCH_CARD_DEL,
  GETTER_STREAM_CHAT_CLEAR
} from '@/store/const/type'
import StreamCtrl from '@/comp/stream-ctrl'
import StreamChatClear from '@/comp/stream-chat-clear'
import WatchCardDel from '@/comp/watch-card-del'
import FixedSVG from '@/comp/fixed-svg/fixed-svg'
export default {
  name: 'App',
  components: { StreamChatClear, WatchCardDel, StreamCtrl, FixedSVG },
  props: {
    name: { type: String }
  },
  data() {
    return {
      navBroadcast: { label: 'Создать Трансляцию', to: '/broadcast/new' },
      navVidecam: { label: 'Добавить камеру', to: `/${APP_ROUTE.VIDECAM}/new` }
    }
  },
  methods: {
    logout() {
      console.log('logout')
    },
    login() {
      console.log('handle login')
    },
    setNavBroadcast(name, params) {
      const isEdit = name === ROUTE_NAME_BROADCAST.EDIT
      const label = isEdit ? 'Редактировать трансляцию' : 'Создать Трансляцию'
      const to = isEdit ? `/${APP_ROUTE.BROADCAST}/${params.id}` : `/${APP_ROUTE.BROADCAST}/new`
      this.navBroadcast = { label, to }
    },
    setNavVidecam(name, params) {
      if (ROUTE_NAME_VIDECAM.NEW === name) {
        this.navVidecam = { label: 'Добавить камеру', to: `/${APP_ROUTE.VIDECAM}/new` }
        return
      }
      if (ROUTE_NAME_VIDECAM.EDIT === name) {
        this.navVidecam = {
          label: 'Редактировать камеру',
          to: `/${APP_ROUTE.VIDECAM}/${params.id}`
        }
        return
      }
      if (ROUTE_NAME_VIDECAM.ADMIN === name) {
        this.navVidecam = { label: 'Статус камер', to: `/${APP_ROUTE.VIDECAM}/admin` }
        return
      }
      this.navVidecam = { label: 'Добавить камеру', to: `/${APP_ROUTE.VIDECAM}/new` }
    }
  },
  computed: {
    /**
     * hidden используется для того, чтобы скрыть/показать часть
     * меню в навигационной панеле, эти права у пользователя могут
     * появиться в любой момент, если такое произойдет, то мы используем
     * реактивность computed-свойств для того, чтобы приложение автоматически
     * среагировало на эти изменения
     */
    nav() {
      return this.isAdmin
        ? [
            this.navBroadcast,
            this.navVidecam,
            { label: 'Мой канал', to: `/${APP_ROUTE.STREAM_SETTING}/${this.user.username}` },
            { label: 'Видеозаписи', to: `/${APP_ROUTE.RECORD}` },
            { label: 'Управление', to: `/${APP_ROUTE.CONTROL}` },
            { label: 'ИИ', to: `/${APP_ROUTE.AI}` },
            { label: 'Помощь', to: `/${APP_ROUTE.HELP}` }
          ]
        : [
            this.navBroadcast,
            { label: 'Мой канал', to: `/${APP_ROUTE.STREAM_SETTING}/${this.user.username}` },
            { label: 'Помощь', to: `/${APP_ROUTE.HELP}` }
          ]
    },
    header() {
      return {
        avatar: this.isAnon
          ? {
              value: 'Анонимный режим'
            }
          : {
              value: this.name,
              src: `${URL.ISSO_AVATAR}/${this.user.avatar}`
            },
        logo: logoMap[__STAND_TYPE__],
        nav: this.nav
      }
    },
    ...mapGetters({
      isVisibleStreamCtrl: GETTER_STREAM_CTRL.IS_VISIBLE,
      isVisibleWatchCardDel: GETTER_WATCH_CARD_DEL.IS_VISIBLE,
      isVisibleStreamChatClear: GETTER_STREAM_CHAT_CLEAR.IS_VISIBLE,
      user: GETTER_APP.USER,
      isAnon: GETTER_APP.IS_ANON,
      isAdmin: GETTER_APP.IS_ADMIN
    })
  },
  watch: {
    $route(path) {
      this.setNavBroadcast(path.name, path.params)
      this.setNavVidecam(path.name, path.params)
    }
  },
  created() {
    if (!__DEV__) {
      console.info('VERSION', __VERSION__)
      // console.info('RELEASE_TIME', __RELEASE_TIME__)
      // console.info('STAND_TYPE', __STAND_TYPE__)
      // console.info('COMMIT', __COMMIT__)
      // console.info('PIPELINE_URL', __PIPELINE_URL__)
    }
    this.setNavBroadcast(this.$route.name, this.$route.params)
  },
  mounted() {
    const xmlns = 'http://www.w3.org/2000/svg'

    let parent = document.querySelector('.mts-logo')
    let p = document.createElementNS(xmlns, 'svg')
    p.setAttributeNS(null, 'version', '1.0')
    p.setAttributeNS(null, 'viewBox', '0 0 125 125')
    p.setAttributeNS(null, 'class', 'mts-logo__icon')

    p.innerHTML = `<g transform="translate(0.000000,125.000000) scale(0.050000,-0.050000)" fill="#f6083a" stroke="none"> <path d="M0 1250 l0 -1250 1250 0 1250 0 0 1250 0 1250 -1250 0 -1250 0 0 -1250z m481 890 c104 -220 95 -220 196 1 l83 181 105 -6 105 -6 6 -295 5 -295 -80 0 -81 0 0 195 -1 195 -85 -160 c-82 -152 -89 -160 -155 -160 -66 0 -73 7 -153 160 l-85 160 0 -195 -1 -195 -80 0 -80 0 0 287 c0 326 -5 313 121 313 l95 0 85 -180z m1839 110 l0 -70 -119 0 -120 0 -5 -225 -6 -225 -75 -6 -75 -6 0 231 0 231 -130 0 c-129 0 -130 0 -130 57 0 83 0 83 343 83 l317 0 0 -70z m-132 -1498 c73 -30 132 -100 132 -156 0 -53 -126 -49 -163 5 -49 72 -236 77 -306 8 -41 -42 -41 -206 0 -265 44 -62 255 -65 294 -4 26 42 175 60 175 21 0 -213 -463 -270 -608 -75 -59 79 -59 288 -1 367 87 119 316 166 477 99z"/>
 </g> `
    parent.replaceChild(p, parent.children[0])
  }
}
</script>
